import { BankAccount } from "../BankAccount/BankAccount.type";
import { CRVPayment } from "../CRVPayment/CRVPayment.type";
import { PostCheck } from "../PostCheck/PostCheck.type";
import { Replenishment } from "../Replenishment/Replenishment.type";
import { ApiCallState } from "../Utils";

export type AccountTransaction = {
  id: number;
  user_id: number;
  bank_account_id: number;

  crv_payment_id: number; // +
  post_check_id: number; // -
  replenishment_id: number; // -

  payee: string;
  pv_crv_number: string;
  check_number: string;
  transaction_type: string;
  amount: number;
  description: string;
  date: Date;

  bank_account: BankAccount;
  crv_payment: CRVPayment;
  post_check: PostCheck;
  replenishment: Replenishment;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AccountTransactionStateTypes = {
  fetchAll: ApiCallState<AccountTransaction[]>;
};

export const AccountTransactionActionTypes = {
  FETCH_ALL_ACCOUNT_TRANSACTION: "FETCH_ALL_ACCOUNT_TRANSACTION",
  FETCH_ALL_ACCOUNT_TRANSACTION_RESET: "FETCH_ALL_ACCOUNT_TRANSACTION_RESET",
  FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE:
    "FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE",
  FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS",
};
