import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffActionTypes } from "./Staff.type";

export function* fetchAllStaffs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: StaffActionTypes.FETCH_ALL_STAFF_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffActionTypes.FETCH_ALL_STAFF_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllStaffDetails(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff/detail?project_id=${action.payload}`
    );
    yield put({
      type: StaffActionTypes.FETCH_ALL_STAFF_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffActionTypes.FETCH_ALL_STAFF_DETAIL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff/${action.payload}`);
    yield put({
      type: StaffActionTypes.FETCH_ONE_STAFF_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffActionTypes.FETCH_ONE_STAFF_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffs() {
  yield takeLatest(StaffActionTypes.FETCH_ALL_STAFF, fetchAllStaffs);
}

export function* watcherFetchAllStaffDetails() {
  yield takeLatest(
    StaffActionTypes.FETCH_ALL_DETAIL_STAFF,
    fetchAllStaffDetails
  );
}

export function* watcherFetchOneStaffs() {
  yield takeLatest(StaffActionTypes.FETCH_ONE_STAFF, fetchOneStaffs);
}
