import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { RouteConstants } from "./Constants";
import ErrorBoundary from "../containers/Errors/ErrorBoundary/ErrorBoundary.component";
import { checkAuthorization, isLoggedIn } from "../utilities/utilities";
import Layout from "../containers/Layouts/Layout/Layout.component";
import LoadingIndicator from "../components/common/Loading";
import { BUILD, BuildType } from "../constants/Constants";
import { EmailConfirmation } from "../components/Signup/EmailConfirmation";
import CheckerComponent from "../components/Signup/Checker";
import ResetEmail from "../components/Signup/ResetEmail/resetEmail";
import GoogleAuthForm from "../components/Signup/GoogleAuth/googleAuthForm.component";
import GoogleAuth from "../components/Signup/Checker/google-auth";
import Welcome from "../components/HR/EmployeeApplication/welcome";
import EmployeeApplication from "../components/HR/EmployeeApplication/employeeApplication";
import EmployeeApplicationSuccess from "../components/HR/EmployeeApplication/employee-application-success";

const ClientSummaryComponent = lazy(
  () => import("../containers/ClientSummary/ClientSummary.component")
);

const ConsultantPaymentComponent = lazy(
  () => import("../containers/ConsultantPayment/ConsultantPayment.component")
);
const ContractorPaymentComponent = lazy(
  () => import("../containers/ContractorPayment/ContractorPayment.component")
);
const ConsultantSummaryComponent = lazy(
  () => import("../containers/ConsultantSummary/ConsultantSummary.component")
);

const ProjectListComponent = lazy(
  () => import("../containers/Project/List.component")
);
const EnterpriseLoginComponent = lazy(() => import("../components/Login"));

const SignupComponent = lazy(() => import("../components/Signup/index"));

const SummaryComponent = lazy(
  () => import("../containers/Summary/Summary.component")
);

const RegisterProjectComponent = lazy(
  () => import("../components/Project/ProjectRegistration")
);

const InventoryComponent = lazy(
  () => import("../containers/Inventory/Inventory.component")
);

const FinanceComponent = lazy(
  () => import("../containers/Finance/Finance.component")
);

const DocumentComponent = lazy(
  () => import("../containers/Documents/Documents.component")
);

const ProcurementComponent = lazy(
  () => import("../containers/Procurement/Procurement.component")
);

const MasterScheduleComponent = lazy(
  () => import("../containers/MasterSchedule/MasterSchedule.component")
);

// import TestComponent from "../test";

// Re-Initialize the onbeforeunload event listener

const Routes = () => {
  const PrivateRoute = ({ ...props }: any) => {
    const { children, ...rest } = props;
    const isAuthenticated = isLoggedIn();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = checkAuthorization(rest.path);
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated && isAuthorized ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  };

  return (
    <>
      <ErrorBoundary>
        <Switch>
          <Route exact path={RouteConstants.LOGIN}>
            <Suspense fallback={<LoadingIndicator />}>
              <EnterpriseLoginComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.SIGN_UP}>
            <Suspense fallback={<LoadingIndicator />}>
              <SignupComponent />
            </Suspense>
          </Route>

          <Route path={RouteConstants.EMAIL_CONFIRMATION}>
            <EmailConfirmation />
          </Route>

          <Route path={RouteConstants.GOOGLE_AUTH_FORM}>
            <GoogleAuthForm />
          </Route>

          <Route path={RouteConstants.EMPLOYEE_APPLICATION}>
            <Welcome />
          </Route>

          <Route path={RouteConstants.EMPLOYEE_APPLICATION2}>
            <EmployeeApplication />
          </Route>

          <Route path={RouteConstants.EMPLOYEE_APPLICATION_SUCCESS}>
            <EmployeeApplicationSuccess />
          </Route>

          <Route path={RouteConstants.GOOGLE_AUTH}>
            <GoogleAuth />
          </Route>
          <Route path={RouteConstants.CHECKER}>
            <CheckerComponent />
          </Route>
          <Route path={RouteConstants.RESET}>
            <ResetEmail />
          </Route>

          <PrivateRoute exact path={RouteConstants.DASHBOARD}>
            <Redirect to={{ pathname: RouteConstants.DASHBOARDS }} />
          </PrivateRoute>

          {/* HR */}

          {/* FINANCE */}
          <PrivateRoute exact path={RouteConstants.FINANCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FinanceComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* Project Manager */}

          <PrivateRoute exact path={RouteConstants.SUMMARY}>
            <Layout></Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.CONTRACTOR_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <SummaryComponent location={null} />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROJECT_LIST}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="1" project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CONSULTANT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ConsultantSummaryComponent key="15" location="" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CLIENT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ClientSummaryComponent key="15" location="" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROJECTS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="9" project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_PROJECT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PRE_CONTRACT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="1" project_type="pre-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_PRE_CONTRACT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="pre-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.DOCUMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.INVENTORY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <InventoryComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROCUREMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProcurementComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PAYMENT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ConsultantPaymentComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.CONTRACTOR_PAYMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ContractorPaymentComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.MASTER_SCHEDULE_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <MasterScheduleComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Routes);
