import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ReplenishmentTransactionActionTypes } from "./ReplenishmentTransaction.type";

export function* fetchAllReplenishmentTransactions(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-transaction?${query}`
    );
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllAllReplenishmentTransactions(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-transaction?${query}`
    );
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPreviousReplenishmentTransactions(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-transaction?${query}`
    );
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneReplenishmentTransactions(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-transaction/${action.payload}?${query}`
    );
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllReplenishmentTransactions() {
  yield takeLatest(
    ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION,
    fetchAllReplenishmentTransactions
  );
}

export function* watcherFetchAllAllReplenishmentTransactions() {
  yield takeLatest(
    ReplenishmentTransactionActionTypes.FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION,
    fetchAllAllReplenishmentTransactions
  );
}

export function* watcherFetchPreviousReplenishmentTransactions() {
  yield takeLatest(
    ReplenishmentTransactionActionTypes.FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION,
    fetchPreviousReplenishmentTransactions
  );
}

export function* watcherFetchOneReplenishmentTransactions() {
  yield takeLatest(
    ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION,
    fetchOneReplenishmentTransactions
  );
}
