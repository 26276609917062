import { Replenishment } from "../Replenishment/Replenishment.type";
import { ApiCallState } from "../Utils";

export type ReplenishmentTransaction = {
  id: number;
  user_id: number;
  replenishment_id: number;

  payee: string;
  description: string;
  pcpv_number: string;
  amount: number;

  date: Date;

  replenishment: Replenishment;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ReplenishmentTransactionStateTypes = {
  fetchAll: ApiCallState<ReplenishmentTransaction[]>;
  fetchAllAll: ApiCallState<ReplenishmentTransaction[]>;
  fetchPrevious: ApiCallState<ReplenishmentTransaction[]>;
  fetchOne: ApiCallState<ReplenishmentTransaction | {}>;
};

export const ReplenishmentTransactionActionTypes = {
  FETCH_ALL_REPLENISHMENT_TRANSACTION: "FETCH_ALL_REPLENISHMENT_TRANSACTION",
  FETCH_ALL_REPLENISHMENT_TRANSACTION_RESET:
    "FETCH_ALL_REPLENISHMENT_TRANSACTION_RESET",
  FETCH_ALL_REPLENISHMENT_TRANSACTION_FAILURE:
    "FETCH_ALL_REPLENISHMENT_TRANSACTION_FAILURE",
  FETCH_ALL_REPLENISHMENT_TRANSACTION_SUCCESS:
    "FETCH_ALL_REPLENISHMENT_TRANSACTION_SUCCESS",

  FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION:
    "FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION",
  FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_RESET:
    "FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_RESET",
  FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_FAILURE:
    "FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_FAILURE",
  FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ALL_REPLENISHMENT_TRANSACTION_SUCCESS",

  FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION:
    "FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION",
  FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_RESET:
    "FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_RESET",
  FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_FAILURE:
    "FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_FAILURE",
  FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_SUCCESS:
    "FETCH_PREVIOUS_REPLENISHMENT_TRANSACTION_SUCCESS",

  FETCH_ONE_REPLENISHMENT_TRANSACTION: "FETCH_ONE_REPLENISHMENT_TRANSACTION",
  FETCH_ONE_REPLENISHMENT_TRANSACTION_RESET:
    "FETCH_ONE_REPLENISHMENT_TRANSACTION_RESET",
  FETCH_ONE_REPLENISHMENT_TRANSACTION_FAILURE:
    "FETCH_ONE_REPLENISHMENT_TRANSACTION_FAILURE",
  FETCH_ONE_REPLENISHMENT_TRANSACTION_SUCCESS:
    "FETCH_ONE_REPLENISHMENT_TRANSACTION_SUCCESS",
};
