import { Absence } from "../Absence/Absence.type";
import { Allowance } from "../Allowance/Allowance.type";
import { Department } from "../Department/Department.type";
import { ManpowerOvertime } from "../ManpowerOvertime/ManpowerOvertime.type";
import { Project } from "../Project/Project.type";
import { ProjectStaff } from "../ProjectStaff/ProjectStaff.type";
import { ApiCallState } from "../Utils";

export type Staff = {
  id: any;
  department_id: number;
  name: string;
  sex: string;
  description: string;
  salary: number;
  status: string;
  type: string;
  woreda: number;
  email: string;
  city: string;
  termination_date: Date;
  department: Department | null;
  projects: { name: string; id: number; project_staff: ProjectStaff }[] | null;
  date: string;
  key: number;
  manpower_overtimes: ManpowerOvertime[];
  emergency_contact: EmergencyContact;
  employee_histories: EmployeeHistory[];
  employee_requests: {
    request_type: string;
    date: string;
    leave_request: {
      paid: number;
      unpaid: number;
      type: string;
      description: string;
      start_date: string;
      end_date: string;
      leave_finance: string;
    } | null;
    loan_request: {
      repayment_type: string;
      repayment_amount: number;
      amount: number;
      paid: number;
      reason: string;
    } | null;
    overtime_request: {
      description: string;
      date: string;
      overtime_start: string;
      overtime_end: string;
    } | null;
    travel_request: null;
    benefit_request: {
      type: string;
      description: string;
      amount: number;
    } | null;
  }[];
  equipment_wo_staff: { task: string };
  phone_number: string;
  period: string;
  allowances: Allowance[];
  createdAt: string;
  photo: string;
  unique_uuid: string;
  absences: Absence[];
};

export type EmergencyContact = {
  id: number;
  name: string;
  phone_number: string;
  relation: string;
};

export type EmployeeHistory = {
  id: number;
  staff_id: number;
  department_id: number;
  salary: number;
  allowance: number;
  overtime_salary: number;
  type: string;
  change: string;
  date: string;
  createdAt: "2021-04-21T15:02:37.000Z";
  updatedAt: "2021-04-21T15:02:37.000Z";
};

export type StaffStateTypes = {
  fetchAll: ApiCallState<Staff[]>;
  fetchOne: ApiCallState<Staff | null>;
  fetchAllDetail: ApiCallState<Staff[]>;
};

export const StaffActionTypes = {
  FETCH_ALL_STAFF: "FETCH_ALL_STAFF",
  FETCH_ALL_STAFF_RESET: "FETCH_ALL_STAFF_RESET",
  FETCH_ALL_STAFF_FAILURE: "FETCH_ALL_STAFF_FAILURE",
  FETCH_ALL_STAFF_SUCCESS: "FETCH_ALL_STAFF_SUCCESS",

  FETCH_ALL_DETAIL_STAFF: "FETCH_ALL_DETAIL_STAFF",
  FETCH_ALL_STAFF_DETAIL_RESET: "FETCH_ALL_STAFF_DETAIL_RESET",
  FETCH_ALL_STAFF_DETAIL_FAILURE: "FETCH_ALL_STAFF_DETAIL_FAILURE",
  FETCH_ALL_STAFF_DETAIL_SUCCESS: "FETCH_ALL_STAFF_DETAIL_SUCCESS",

  FETCH_ONE_STAFF: "FETCH_ONE_STAFF",
  FETCH_ONE_STAFF_RESET: "FETCH_ONE_STAFF_RESET",
  FETCH_ONE_STAFF_FAILURE: "FETCH_ONE_STAFF_FAILURE",
  FETCH_ONE_STAFF_SUCCESS: "FETCH_ONE_STAFF_SUCCESS",
};
