import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllAccounts,
  watcherFetchOneAccounts,
} from "./Account/Account.saga";
import {
  watcherFetchAllBoq,
  watcherFetchDetailBoq,
  watcherFetchOneBoq,
} from "./Boq/Boq.saga";
import { watcherFetchAllBoqActivityPlans } from "./BoqActivityPlan/BoqActivityPlan.saga";
import { watcherFetchAllBoqPlans } from "./BoqPlan/BoqPlan.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllCustomers } from "./Customer/Customer.saga";
import { watcherFetchAllDepartments } from "./Department/Department.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchEquipmentInventories } from "./EquipmentInventory/EquipmentInventory.saga";
import { watcherFetchAllEquipmentPlans } from "./EquipmentPlan/EquipmentPlan.saga";
import { watcherFetchEquipmentTransfer } from "./EquipmentTransfer/EquipmentTransfer.saga";
import { watcherFetchAllEquipmentUsage } from "./EquipmentUsage/EquipmentUsage.saga";
import { watcherFetchAllEquipmentUsagePlans } from "./EquipmentUsagePlan/EquipmentUsagePlan.saga";
import { watcherFetchAllExpense } from "./Expense/Expense.saga";
import { watcherFetchAllInvoice } from "./Invoice/Invoice.saga";
import { watcherFetchAllLabour } from "./Labour/Labour.saga";
import { watcherFetchAllLabourPlans } from "./LabourPlan/LabourPlan.saga";
import { watcherFetchAllLabourUsage } from "./LabourUsage/LabourUsage.saga";
import { watcherFetchMaterials } from "./Material/Material.saga";
import {
  watcherFetchMaterialInventories,
  watcherFetchMaterialInventoriesAnalysis,
} from "./MaterialInventory/MaterialInventory.saga";
import { watcherFetchAllMaterialPlans } from "./MaterialPlan/MaterialPlan.saga";
import { watcherFetchMaterialTransfer } from "./MaterialTransfer/MaterialTransfer.saga";
import { watcherFetchAllPayment } from "./Payment/Payment.saga";
import { watcherFetchAllPaymentRetention } from "./PaymentRetention/PaymentRetention.saga";
import {
  watcherFetchAllEmployeeAccommodationPlan,
  watcherFetchOneEmployeeAccommodationPlan,
} from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
} from "./Project/Project.saga";
import { watcherFetchAllProjectStaffs } from "./ProjectStaff/ProjectStaff.saga";
import { watcherFetchAllRebar } from "./Rebar/Rebar.saga";
import { watcherFetchAllServices } from "./Service/Service.saga";
import {
  watcherFetchAllStaffs,
  watcherFetchOneStaffs,
  watcherFetchAllStaffDetails,
} from "./Staff/Staff.saga";
import { watcherFetchAllStaffPlans } from "./StaffPlan/StaffPlan.saga";
import {
  watcherFetchAllSubContracts,
  watcherFetchOneSubContracts,
} from "./SubContract/SubContract.saga";
import { watcherFetchAllSubContractPlans } from "./SubContractPlan/SubContractPlan.saga";
import { watcherFetchSupplies } from "./Supplier/Supplier.saga";
import { watcherFetchAllTakeoffs } from "./Takeoff/Takeoff.saga";
import {
  watcherFetchAllUnitBreakdownsPost,
  watcherFetchAllUnitBreakdownsPre,
} from "./UnitBreakdown/UnitBreakdown.saga";
import { watcherFetchAllUsage } from "./Usage/Usage.saga";
import {
  watcherFetchAllVariation,
  watcherFetchBoqVariation,
  watcherFetchPIDVariation,
} from "./Variation/Variation.saga";
import { watcherFetchAllManpowerOvertime } from "./ManpowerOvertime/ManpowerOvertime.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import { watcherFetchAllEmployeeExtras } from "./EmployeeExtra/EmployeeExtra.saga";
import {
  watcherFetchAllUser,
  watcherFetchOneUser,
  watcherFetchLoggedInUser,
  watcherFetchAssignedUser,
} from "./User/User.saga";
import { watcherFetchAllAttendances } from "./Attendance/Attendance.saga";
import { watcherFetchAllEmployeeRequests } from "./EmployeeRequest/EmployeeRequest.saga";
import {
  watcherFetchAllMaterialRequisitions,
  watcherFetchOneMaterialRequisitions,
} from "./MaterialRequisition/MaterialRequisition.saga";
import {
  watcherFetchAllPurchaseRequisitions,
  watcherFetchOnePurchaseRequisitions,
} from "./PurchaseRequisition/PurchaseRequisition.saga";
import {
  watcherFetchAllPurchaseOrders,
  watcherFetchOnePurchaseOrders,
} from "./PurchaseOrder/PurchaseOrder.saga";
import { watcherFetchAllPurchaseBillings } from "./PurchaseBilling/PurchaseBilling.saga";
import { watcherFetchAllGoodReceived } from "./GoodReceived/GoodReceived.saga";
import { watcherFetchAllRenters } from "./Renter/Renter.saga";
import { watcherFetchAllGoodOut } from "./GoodOut/GoodOut.saga";
import { watcherFetchAllGoodReturn } from "./GoodReturn/GoodReturn.saga";
import { watcherFetchEmployeeHistory } from "./EmployeeHistory/EmployeeHistory.saga";
import { watcherFetchAllDocumentWorkOrders } from "./DocumentWorkOrder/DocumentWorkOrder.saga";
import { watcherFetchAllEquipmentWO } from "./EquipmentWO/EquipmentWO.saga";
import { watcherFetchAllPriceEscalations } from "./PriceEscalation/PriceEscalation.saga";
import { watcherFetchAllHRPolicy } from "./HRPolicy/HRPolicy.saga";
import { watcherFetchAllAbsences } from "./Absence/Absence.saga";
import { watcherFetchAllEmployeeDocuments } from "./EmployeeDocument/EmployeeDocument.saga";
import {
  watcherFetchAllLabourPayrolls,
  watcherFetchAllStaffPayrolls,
  watcherFetchAllPayrolls,
  watcherFetchAllPayrollReport,
  watcherFetchAllPayrollStaffHistory,
} from "./Payroll/Payroll.saga";
import { watcherFetchAllClients } from "./Client/Client.saga";
import {
  watcherFetchAllMeetings,
  watcherFetchOneMeetings,
} from "./Meeting/Meeting.saga";
import { watcherFetchAllAllowances } from "./Allowance/Allowance.saga";
import { watcherFetchAllAllowancePolicy } from "./AllowancePolicy/AllowancePolicy.saga";
import {
  watcherFetchAllStandard,
  watcherFetchOneStandard,
} from "./Standard/Standard.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";
import {
  watcherFetchAllBoqStandard,
  watcherFetchOneBoqStandard,
} from "./BoqStandard/BoqStandard.saga";
import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";
import {
  watcherFetchAllPaymentCertificates,
  watcherFetchOnePaymentCertificates,
} from "./PaymentCertificate/PaymentCertificate.saga";
import {
  watcherFetchAllPaymentCertificateTakeoffs,
  watcherFetchOnePaymentCertificateTakeoffs,
} from "./PaymentCertificateTakoff/PaymentCertificateTakeoff.saga";
import {
  watcherFetchAllPaymentCertificateRebars,
  watcherFetchOnePaymentCertificateRebars,
} from "./PaymentCertificateRebar/PaymentCertificateRebar.saga";
import {
  watcherFetchAllPaymentCertificateRoads,
  watcherFetchOnePaymentCertificateRoads,
} from "./PaymentCertificateRoad/PaymentCertificateRoad.saga";
import {
  watcherFetchAllPaymentCertificateAggregates,
  watcherFetchOnePaymentCertificateAggregates,
} from "./PaymentCertificateAggregate/PaymentCertificateAggregate.saga";

import {
  watcherFetchAllInspectionForm,
  watcherFetchOneInspectionForm,
} from "./InspectionForm/InspectionForm.saga";
import {
  watcherFetchAllInspection,
  watcherFetchOneInspection,
} from "./Inspection/Inspection.saga";
import { watcherFetchAllCastings } from "./Casting/Casting.saga";
import {
  watcherFetchAllMaterialRequestApprovals,
  watcherFetchOneMaterialRequestApprovals,
} from "./MaterialRequestApproval/MaterialRequestApproval.saga";
import {
  watcherFetchAllTestResult,
  watcherFetchOneTestResult,
} from "./TestResult/TestResult.saga";
import {
  watcherFetchAllTestRequest,
  watcherFetchOneTestRequest,
} from "./TestRequest/TestRequest.saga";
import {
  watcherFetchAllSubmittals,
  watcherFetchOneSubmittals,
} from "./Submittal/Submittal.saga";
import { watcherFetchAllSiteDiary } from "./SiteDiary/SiteDiary.saga";
import { watcherFetchAllWeekReport } from "./WeekReport/WeekReport.saga";
import {
  watcherFetchAllQueries,
  watcherFetchOneQueries,
} from "./Query/Query.saga";
import {
  watcherFetchAllMasterSchedules,
  watcherFetchOneMasterSchedules,
} from "./MasterSchedule/MasterSchedule.saga";
import { watcherFetchAllRFIs } from "./RFI/RFI.saga";
import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import { watcherFetchAllEmployeeReview } from "./EmployeeReview/EmployeeReview.saga";
import { watcherFetchAllBonuses } from "./Bonus/Bonus.saga";

import {
  watcherFetchAllVacancies,
  watcherFetchVacanciesByAttributes,
  watcherFetchVacanciesByJobId,
} from "./Vacancy/Vacancy.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllMedias } from "./Media/Media.saga";
import { watcherFetchAllSHEs } from "./SHE/SHE.saga";
import { watcherFetchAllSharedDocuments } from "./SharedDocument/SharedDocument.saga";

import { watcherFetchAllBankAccounts } from "./BankAccount/BankAccount.saga";
import {
  watcherFetchAllLetters,
  watcherFetchOneLetters,
} from "./Letter/Letter.saga";

import { watcherFetchAllAccountTransactions } from "./AccountTransaction/AccountTransaction.saga";
import { watcherFetchAllRentContracts } from "./RentContract/RentContract.saga";
import {
  watcherFetchAllRiskLogs,
  watcherFetchOneRiskLogs,
} from "./RiskLog/RiskLog.saga";
import {
  watcherFetchAllStaffWorks,
  watcherFetchOneStaffWorks,
} from "./StaffWork/StaffWork.saga";
import {
  watcherFetchAllReceivables,
  watcherFetchOneReceivables,
} from "./Receivable/Receivable.saga";

import { watcherFetchAllAttachments } from "./Attachment/Attachment.saga";
import {
  watcherFetchAllPaymentFiles,
  watcherFetchOnePaymentFiles,
} from "./PaymentFile/PaymentFile.saga";

import {
  watcherFetchAllPettyCashes,
  watcherFetchOnePettyCashes,
} from "./PettyCash/PettyCash.saga";

import {
  watcherFetchAllReplenishments,
  watcherFetchOneReplenishments,
} from "./Replenish/Replenishment.saga";

import {
  watcherFetchAllReplenishmentTransactions,
  watcherFetchOneReplenishmentTransactions,
} from "./ReplenishTransaction/ReplenishmentTransaction.saga";

import {
  watcherFetchAllReplenishmentRequests,
  watcherFetchOneReplenishmentRequests,
} from "./ReplenishRequest/ReplenishmentRequest.saga";

import {
  watcherFetchAllTestEvaluations,
  watcherFetchOneTestEvaluations,
} from "./TestEvaluation/TestEvaluation.saga";

import {
  watcherFetchAllVariationFiles,
  watcherFetchOneVariationFiles,
} from "./VariationFile/VariationFile.saga";
import {
  watcherFetchAllPriceEscalationFiles,
  watcherFetchOnePriceEscalationFiles,
} from "./PriceEscalationFile/PriceEscalationFile.saga";

import {
  watcherFetchAllCRVPayments,
  watcherFetchOneCRVPayments,
} from "./CRVPayment/CRVPayment.saga";

import {
  watcherFetchAllPostChecks,
  watcherFetchOnePostChecks,
} from "./PostCheck/PostCheck.saga";
import {
  watcherFetchAllSiteBooks,
  watcherFetchOneSiteBooks,
} from "./SiteBook/SiteBook.saga";
import {
  watcherFetchAllMonthlyReports,
  watcherFetchOneMonthlyReports,
} from "./MonthlyReport/MonthlyReport.saga";
import { watcherFetchAllDailyReports } from "./DailyReport/DailyReport.saga";
import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";
import { watcherFetchAllPayables } from "./Payable/Payable.saga";
import {
  watcherFetchAllMemos,
  watcherFetchCountMemos,
  watcherFetchOneMemos,
} from "./Memo/Memo.saga";
import { watcherFetchAllBuildings } from "./Building/Building.saga";
import { watcherFetchAllApartments } from "./Apartment/Apartment.saga";
import { watcherFetchAllApartmentExpenses } from "./ApartmentExpense/ApartmentExpense.saga";
import { watcherFetchAllContractSaless } from "./ContractSales/ContractSales.saga";
import { watcherFetchAllEstimatedBuildingCosts } from "./EstimatedBuildingCost/EstimatedBuildingCost.saga";
import {
  watcherFetchAllProformas,
  watcherFetchOneProformas,
} from "./Proforma/Proforma.saga";
import { watcherFetchAllCurrencies } from "./Currency/Currency.saga";
import { watcherFetchAllApartmentEstimateCosts } from "./ApartmentEstimateCost/ApartmentEstimateCost.saga";

import {
  watcherFetchAllProcurementPlans,
  watcherFetchOneProcurementPlans,
} from "./ProcurementPlan/ProcurementPlan.saga";

import {
  watcherFetchAllUserAssignments,
  watcherFetchOneUserAssignments
} from "./UserAssignment/UserAssignment.saga";

import {
  watcherFetchAllDocumentAssignments,
  watcherFetchOneDocumentAssignments
} from "./DocumentAssignment/DocumentAssignment.saga";

import {
  watcherFetchAllReportss,
  watcherFetchOneReportss
} from "./Reports/Reports.saga";

import {
  watcherFetchAllReviewForApprovals,
  watcherFetchOneReviewForApprovals
} from "./ReviewForApproval/ReviewForApproval.saga";

import {
  watcherFetchAllRequestForInformations,
  watcherFetchOneRequestForInformations
} from "./RequestForInformation/RequestForInformation.saga";

import {
  watcherFetchAllScheduleMeetings,
  watcherFetchOneScheduleMeetings
} from "./ScheduleMeeting/ScheduleMeeting.saga";

export default function* RootSaga() {
  yield all([
    /**
     * Project Sagas
     */
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchAllTakeoffs),
    call(watcherFetchAllRebar),
    call(watcherFetchAllBoq),
    call(watcherFetchOneBoq),
    call(watcherFetchAllBoqPlans),
    call(watcherFetchAllBoqActivityPlans),
    call(watcherFetchAllSubContractPlans),
    call(watcherFetchMaterials),
    call(watcherFetchSupplies),
    call(watcherFetchMaterialInventories),
    call(watcherFetchMaterialTransfer),
    call(watcherFetchAllUsage),
    call(watcherFetchEquipmentInventories),
    call(watcherFetchEquipmentTransfer),
    call(watcherFetchAllEquipmentUsage),
    call(watcherFetchAllLabourUsage),
    call(watcherFetchAllLabour),
    call(watcherFetchAllEquipmentPlans),
    call(watcherFetchAllEquipmentUsagePlans),
    call(watcherFetchAllMaterialPlans),
    call(watcherFetchAllLabourPlans),
    call(watcherFetchDetailBoq),
    call(watcherFetchAllDepartments),
    call(watcherFetchAllStaffs),
    call(watcherFetchAllStaffDetails),
    call(watcherFetchAllStaffPlans),
    call(watcherFetchAllAccounts),
    call(watcherFetchAllCustomers),
    call(watcherFetchAllServices),
    call(watcherFetchAllUnitBreakdownsPost),
    call(watcherFetchAllUnitBreakdownsPre),
    call(watcherFetchAllVariation),
    call(watcherFetchBoqVariation),
    call(watcherFetchAllSubContracts),
    call(watcherFetchOneSubContracts),
    call(watcherFetchAllExpense),
    call(watcherFetchAllPayment),
    call(watcherFetchAllInvoice),
    call(watcherFetchAllPaymentRetention),
    call(watcherFetchOneAccounts),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllProjectStaffs),
    call(watcherFetchAllManpowerOvertime),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchAllLabourPayrolls),
    call(watcherFetchAllSchedules),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllEmployeeExtras),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchOneUser),
    call(watcherFetchAllAttendances),
    call(watcherFetchAllEmployeeRequests),
    call(watcherFetchAllMaterialRequisitions),
    call(watcherFetchAllPurchaseRequisitions),
    call(watcherFetchAllPurchaseOrders),
    call(watcherFetchOnePurchaseOrders),
    call(watcherFetchAllPurchaseBillings),
    call(watcherFetchAllGoodReceived),
    call(watcherFetchAllRenters),
    call(watcherFetchAllGoodOut),
    call(watcherFetchAllGoodReturn),
    call(watcherFetchEmployeeHistory),
    call(watcherFetchAllDocumentWorkOrders),
    call(watcherFetchAllEquipmentWO),
    call(watcherFetchAllPriceEscalations),
    call(watcherFetchAllHRPolicy),
    call(watcherFetchAllAbsences),
    call(watcherFetchOneStaffs),
    call(watcherFetchAllEmployeeDocuments),
    call(watcherFetchAllClients),
    call(watcherFetchAllAllowances),
    call(watcherFetchAllAllowancePolicy),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllStandard),
    call(watcherFetchOneStandard),
    call(watcherFetchAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllMeetings),
    call(watcherFetchOneMeetings),
    call(watcherFetchAllEmployeeAccommodationPlan),
    call(watcherFetchOneEmployeeAccommodationPlan),
    call(watcherFetchAllBoqStandard),
    call(watcherFetchOneBoqStandard),
    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchAllPaymentCertificates),
    call(watcherFetchOnePaymentCertificates),
    call(watcherFetchPIDVariation),
    call(watcherFetchAllPaymentCertificateTakeoffs),
    call(watcherFetchOnePaymentCertificateTakeoffs),
    call(watcherFetchAllPaymentCertificateRebars),
    call(watcherFetchOnePaymentCertificateRebars),
    call(watcherFetchAllPaymentCertificateRoads),
    call(watcherFetchOnePaymentCertificateRoads),
    call(watcherFetchAllPaymentCertificateAggregates),
    call(watcherFetchOnePaymentCertificateAggregates),
    call(watcherFetchAllInspectionForm),
    call(watcherFetchOneInspectionForm),
    call(watcherFetchAllInspection),
    call(watcherFetchOneInspection),
    call(watcherFetchAllCastings),
    call(watcherFetchAllTestResult),
    call(watcherFetchOneTestResult),
    call(watcherFetchAllTestRequest),
    call(watcherFetchOneTestRequest),
    call(watcherFetchAllSubmittals),
    call(watcherFetchOneSubmittals),
    call(watcherFetchAllMaterialRequestApprovals),
    call(watcherFetchOneMaterialRequestApprovals),
    call(watcherFetchAllSiteDiary),
    call(watcherFetchAllWeekReport),
    call(watcherFetchAllQueries),
    call(watcherFetchOneQueries),
    call(watcherFetchAllMasterSchedules),
    call(watcherFetchOneMasterSchedules),
    call(watcherFetchAllRFIs),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllEmployeeReview),
    call(watcherFetchAllBonuses),
    call(watcherFetchAllVacancies),
    call(watcherFetchAllApplications),
    call(watcherFetchVacanciesByAttributes),
    call(watcherFetchVacanciesByJobId),
    call(watcherFetchAllPayrollReport),
    call(watcherFetchAllPayrollStaffHistory),
    call(watcherFetchAllMedias),
    call(watcherFetchAllSHEs),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllBankAccounts),
    call(watcherFetchAllLetters),
    call(watcherFetchOneLetters),
    call(watcherFetchAllAccountTransactions),
    call(watcherFetchAllRentContracts),
    call(watcherFetchAllRiskLogs),
    call(watcherFetchOneRiskLogs),
    call(watcherFetchAllStaffWorks),
    call(watcherFetchOneStaffWorks),
    call(watcherFetchAllReceivables),
    call(watcherFetchOneReceivables),
    call(watcherFetchAllAttachments),
    call(watcherFetchMaterialInventoriesAnalysis),
    call(watcherFetchAllPettyCashes),
    call(watcherFetchOnePettyCashes),
    call(watcherFetchAllReplenishments),
    call(watcherFetchOneReplenishments),
    call(watcherFetchAllReplenishmentTransactions),
    call(watcherFetchOneReplenishmentTransactions),
    call(watcherFetchAllReplenishmentRequests),
    call(watcherFetchOneReplenishmentRequests),
    call(watcherFetchAllTestEvaluations),
    call(watcherFetchOneTestEvaluations),
    call(watcherFetchAllPaymentFiles),
    call(watcherFetchOnePaymentFiles),
    call(watcherFetchAllVariationFiles),
    call(watcherFetchOneVariationFiles),
    call(watcherFetchAllPriceEscalationFiles),
    call(watcherFetchOnePriceEscalationFiles),
    call(watcherFetchAllCRVPayments),
    call(watcherFetchOneCRVPayments),
    call(watcherFetchAllPostChecks),
    call(watcherFetchOnePostChecks),
    call(watcherFetchAllSiteBooks),
    call(watcherFetchOneSiteBooks),
    call(watcherFetchAllMonthlyReports),
    call(watcherFetchOneMonthlyReports),
    call(watcherFetchAllDailyReports),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllPayables),
    call(watcherFetchAllMemos),
    call(watcherFetchOneMemos),
    call(watcherFetchCountMemos),
    call(watcherFetchAllBuildings),
    call(watcherFetchAllApartments),
    call(watcherFetchAllApartmentExpenses),
    call(watcherFetchAllContractSaless),
    call(watcherFetchAllEstimatedBuildingCosts),
    call(watcherFetchOneMaterialRequisitions),
    call(watcherFetchOnePurchaseRequisitions),
    call(watcherFetchAllProformas),
    call(watcherFetchOneProformas),
    call(watcherFetchLoggedInUser),
    call(watcherFetchAssignedUser),
    call(watcherFetchAllCurrencies),
    call(watcherFetchAllApartmentEstimateCosts),
    call(watcherFetchAllProcurementPlans),
    call(watcherFetchOneProcurementPlans),
    call(watcherFetchAllUserAssignments),
    call(watcherFetchOneUserAssignments),
    call(watcherFetchAllDocumentAssignments),
    call(watcherFetchOneDocumentAssignments),
    call(watcherFetchAllReportss),
    call(watcherFetchOneReportss),
    call(watcherFetchAllReviewForApprovals),
    call(watcherFetchOneReviewForApprovals),
    call(watcherFetchAllRequestForInformations),
    call(watcherFetchOneRequestForInformations),
    call(watcherFetchAllScheduleMeetings),
    call(watcherFetchOneScheduleMeetings),
  ]);
}
