export type ApiCallState<T> = {
  error: any;
  payload: T;
  isPending: boolean;
  isSuccessful: boolean;
};

export const resetApiCallState = (payload: any) => ({
  error: null,
  payload: payload,
  isPending: false,
  isSuccessful: false,
});
