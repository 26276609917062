import { EquipmentInventory } from "../EquipmentInventory/EquipmentInventory.type";
import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Project, Store } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodReturn = {
  id: number;
  from_id: number;
  to_id: number;
  from_project_return: Project;
  to_project_return: Project;
  from_store_return: Store;
  to_store_return: Store;
  from_project_id: number;
  mode: string;
  inventory_type: string;
  in_date: string;
  out_date: string;
  prepared_by: string;
  checked_by: string;
  authorized_by: string;
  good_return_items: GoodReturnItem[];
  good_return_prepared_by: User | null;
  good_return_checked_by: User | null;
  good_return_authorized_by: User | null;
  is_done: boolean;
  is_approved: boolean;
  is_checked: boolean;
};

export type GoodReturnItem = {
  material_requisition_id: number;
  good_out_id: number;
  material: Material;
  material_id: number;
  material_requisition: MaterialRequisition;
  equipment_inventory: EquipmentInventory;
  quantity: number;
};

export type GoodReturnStateTypes = {
  fetchAll: ApiCallState<GoodReturn[]>;
};

export const GoodReturnActionTypes = {
  FETCH_ALL_GOOD_RETURN: "FETCH_ALL_GOOD_RETURN",
  FETCH_ALL_GOOD_RETURN_RESET: "FETCH_ALL_GOOD_RETURN_RESET",
  FETCH_ALL_GOOD_RETURN_FAILURE: "FETCH_ALL_GOOD_RETURN_FAILURE",
  FETCH_ALL_GOOD_RETURN_SUCCESS: "FETCH_ALL_GOOD_RETURN_SUCCESS",
};
