import { PettyCash } from "../PettyCash/PettyCash.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type ReplenishmentRequest = {
  id: number;
  user_id: number;
  petty_cash_id: number;

  request_number: string;
  amount: number;

  prepared_by_id: number;
  checked_by_id: number;
  approved_by_id: number;

  is_checked: boolean;
  is_approved: boolean;

  is_replenished: boolean;
  date: Date;

  user: User;

  petty_cash: PettyCash;
  prepared_by: User;
  checked_by: User;
  approved_by: User;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ReplenishmentRequestStateTypes = {
  fetchAll: ApiCallState<ReplenishmentRequest[]>;
  fetchOne: ApiCallState<ReplenishmentRequest | {}>;
};

export const ReplenishmentRequestActionTypes = {
  FETCH_ALL_REPLENISHMENT_REQUEST: "FETCH_ALL_REPLENISHMENT_REQUEST",
  FETCH_ALL_REPLENISHMENT_REQUEST_RESET:
    "FETCH_ALL_REPLENISHMENT_REQUEST_RESET",
  FETCH_ALL_REPLENISHMENT_REQUEST_FAILURE:
    "FETCH_ALL_REPLENISHMENT_REQUEST_FAILURE",
  FETCH_ALL_REPLENISHMENT_REQUEST_SUCCESS:
    "FETCH_ALL_REPLENISHMENT_REQUEST_SUCCESS",

  FETCH_ONE_REPLENISHMENT_REQUEST: "FETCH_ONE_REPLENISHMENT_REQUEST",
  FETCH_ONE_REPLENISHMENT_REQUEST_RESET:
    "FETCH_ONE_REPLENISHMENT_REQUEST_RESET",
  FETCH_ONE_REPLENISHMENT_REQUEST_FAILURE:
    "FETCH_ONE_REPLENISHMENT_REQUEST_FAILURE",
  FETCH_ONE_REPLENISHMENT_REQUEST_SUCCESS:
    "FETCH_ONE_REPLENISHMENT_REQUEST_SUCCESS",
};
