import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Project, Store } from "../Project/Project.type";
import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodReceived = {
  id: number;
  type: string;
  purchase_order?: PurchaseOrder;
  supplier?: Supplier;
  material_requisition_id: number;
  material_requisition: MaterialRequisition;
  project?: Project;
  store?: Store;
  project_id: number;
  received_by: string;
  prepared_by: string;
  checked_by: string;
  authorized_by: string;
  date: string;
  inventory_type: string;
  purchase_type: string;
  good_received_type: string;
  good_received_items: GoodReceivedItem[];
  is_done: boolean;
  is_approved: boolean;
  is_checked: boolean;
  gr_authorized_by: null | User;
  gr_checked_by: null | User;
  gr_prepared_by: null | User;
};

export type GoodReceivedItem = {
  material: Material;
  material_id: number;
  unit_price: number;
  quantity: number;
  unit: string;
  identification_number: number;
};

export type GoodReceivedStateTypes = {
  fetchAll: ApiCallState<GoodReceived[]>;
};

export const GoodReceivedActionTypes = {
  FETCH_ALL_GOOD_RECEIVED: "FETCH_ALL_GOOD_RECEIVED",
  FETCH_ALL_GOOD_RECEIVED_RESET: "FETCH_ALL_GOOD_RECEIVED_RESET",
  FETCH_ALL_GOOD_RECEIVED_FAILURE: "FETCH_ALL_GOOD_RECEIVED_FAILURE",
  FETCH_ALL_GOOD_RECEIVED_SUCCESS: "FETCH_ALL_GOOD_RECEIVED_SUCCESS",
};
