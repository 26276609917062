import { ApiCallState } from "../Utils";

export type UserAssignment = {
  id: number;
  project_id: number;
  user_id: number;
  role: string;
  createdAt:string;
  user_assignment_items:UserAssignmentItem[];
};

export type UserAssignmentItem = {
  id: number | null;
  user_assignment_id: number;
  no:string;
  is_category: boolean;
  category: string;
  folder: string;
  is_folder:boolean;
  sub_folder:string;
  is_sub_folder:boolean;
  description: string;
  privilege: string;
  format:string;
};

export type UserAssignmentStateTypes = {
  fetchAll: ApiCallState<UserAssignment[]>;
  fetchOne: ApiCallState<UserAssignment | {}>;
};

export const UserAssignmentActionTypes = {
  FETCH_ALL_USER_ASSIGNMENT: "FETCH_ALL_USER_ASSIGNMENT",
  FETCH_ALL_USER_ASSIGNMENT_RESET: "FETCH_ALL_USER_ASSIGNMENT_RESET",
  FETCH_ALL_USER_ASSIGNMENT_FAILURE: "FETCH_ALL_USER_ASSIGNMENT_FAILURE",
  FETCH_ALL_USER_ASSIGNMENT_SUCCESS: "FETCH_ALL_USER_ASSIGNMENT_SUCCESS",

  FETCH_ONE_USER_ASSIGNMENT: "FETCH_ONE_USER_ASSIGNMENT",
  FETCH_ONE_USER_ASSIGNMENT_RESET: "FETCH_ONE_USER_ASSIGNMENT_RESET",
  FETCH_ONE_USER_ASSIGNMENT_FAILURE: "FETCH_ONE_USER_ASSIGNMENT_FAILURE",
  FETCH_ONE_USER_ASSIGNMENT_SUCCESS: "FETCH_ONE_USER_ASSIGNMENT_SUCCESS",
};
