import { EquipmentInventory } from "../EquipmentInventory/EquipmentInventory.type";
import { Material } from "../Material/Material.type";
import { MaterialInventory } from "../MaterialInventory/MaterialInventory.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Project, Store } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodOut = {
  id: number;
  from_id: number;
  to_id: number;
  from_project_location: Project;
  to_project_location: Project;
  from_store_location: Store;
  to_store_location: Store;
  mode: string;
  inventory_type: string;
  in_date: string;
  out_date: string;
  prepared_by: string;
  checked_by: string;
  from_project_id: number;
  authorized_by: string;
  good_out_items: GoodOutItem[];
  vehicle_used: VehicleUsed[];
  go_authorized_by: User | null;
  go_prepared_by: User | null;
  go_checked_by: User | null;
  is_done: boolean;
  is_approved: boolean;
  is_checked: boolean;
};

export type GoodOutItem = {
  material_requisition_id: number;
  material_id: number;
  material: Material;
  material_requisition: MaterialRequisition;
  material_inventory: MaterialInventory;
  equipment_inventory: EquipmentInventory;
  quantity: number;
  identification_number: number;
  unit: string;
};
export type VehicleUsed = {
  equipment_inventory_id: number;
  equipment_inventory: EquipmentInventory;
  staff_id: number;
};

export type GoodOutStateTypes = {
  fetchAll: ApiCallState<GoodOut[]>;
};

export const GoodOutActionTypes = {
  FETCH_ALL_GOOD_OUT: "FETCH_ALL_GOOD_OUT",
  FETCH_ALL_GOOD_OUT_RESET: "FETCH_ALL_GOOD_OUT_RESET",
  FETCH_ALL_GOOD_OUT_FAILURE: "FETCH_ALL_GOOD_OUT_FAILURE",
  FETCH_ALL_GOOD_OUT_SUCCESS: "FETCH_ALL_GOOD_OUT_SUCCESS",
};
